.pp-footer-nav {
  a {
    color: #111;
    text-decoration-color: #111;
  }

  .delimiter {
    margin: 0 16px;
  }
}
